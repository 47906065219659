<template>
  <div class="container-xs app-grid-large">
    <div class="app-grid-small">
      <AppHeader close-to="/home" title="Join Elude" />
      <router-link class="app-link" to="/login">Or sign in</router-link>
    </div>
    <form class="app-grid-medium" @keydown.enter="onSubmit" @submit.prevent>
      <div>
        <XLabel tag="label" for="first-name">{{
          $t('form.first-name')
        }}</XLabel>
        <FormInput
          id="first-name"
          ref="firstNameField"
          v-model="forms.register.first_name"
          name="first-name"
          autocomplete="first-name"
          autocapitalize="words"
          style="text-transform: capitalize"
          :pattern="REGEX.NAME"
          type="text"
          :validator="value => (value ? true : $t('form.first-name-required'))"
          required
          @valid="forms.vset.register.first_name"
        />
      </div>
      <div>
        <XLabel tag="label" for="last-name">{{ $t('form.last-name') }}</XLabel>
        <FormInput
          id="last-name"
          v-model="forms.register.last_name"
          name="last-name"
          autocomplete="last-name"
          style="text-transform: capitalize"
          :pattern="REGEX.NAME"
          :validator="value => (value ? true : $t('form.last-name-required'))"
          autocapitalize="words"
          type="text"
          required
          @valid="forms.vset.register.last_name"
        />
      </div>
      <div>
        <XLabel tag="label" for="email">{{ $t('form.email') }}</XLabel>
        <FormInput
          id="email"
          v-model="forms.register.email"
          name="email"
          autocomplete="email"
          :validator="value => (value ? true : $t('form.email-required'))"
          type="email"
          required
          @valid="forms.vset.register.email"
        />
      </div>
      <div>
        <XLabel tag="label" for="new-password">{{
          $t('form.password')
        }}</XLabel>
        <p
          style="
            font-family: LuciferSans;
            font-size: 9px;
            text-transform: uppercase;
            letter-spacing: 0.05em;
            color: #444;
          "
        >
          Your password must have at least 8 characters.
        </p>
        <FormInput
          id="new-password"
          v-model="forms.register.password"
          autocomplete="password"
          name="password"
          type="password"
          required
          minlength="8"
          :validator="
            value =>
              !value
                ? $t('form.password-required')
                : value.length < 8
                ? $t('form.password-too-short')
                : true
          "
          @valid="forms.vset.register.password"
          @input="validateConfirmPassword(forms.register.password_confirmation)"
        />
      </div>
      <div>
        <XLabel tag="label" for="confirm-password">{{
          $t('form.confirm-password')
        }}</XLabel>
        <FormInput
          id="confirm-password"
          v-model="forms.register.password_confirmation"
          autocomplete="password"
          name="password"
          type="password"
          @input="validateConfirmPassword"
        />
        <p
          v-if="confirmPassError"
          style="
            color: var(--color-error);
            transition: all 0.5s ease;
            font-family: DDin;
            font-size: 0.75rem;
            max-height: 40px;
            opacity: 1;
          "
        >
          {{ confirmPassError }}
        </p>
      </div>
      <div class="signup-checkbox-group">
        <FormInput
          id="i-agree"
          type="checkbox"
          required
          @valid="forms.vset.register.terms"
        />
        <p>
          {{ $t('signup.i-agree') }}
          <router-link
            to="/terms-of-use"
            class="signup-tos-pp"
            target="_blank"
            >{{ $t('signup.tos') }}</router-link
          >
        </p>
        <FormInput
          id="i-accept"
          type="checkbox"
          required
          @valid="forms.vset.register.policy"
        />
        <p>
          {{ $t('signup.i-accept') }}
          <router-link
            to="/privacy-policy"
            class="signup-tos-pp"
            target="_blank"
            >{{ $t('signup.privacy') }}</router-link
          >
        </p>
      </div>
    </form>

    <x-button
      id="register-button"
      type="large"
      style="margin-top: auto"
      :disabled="!formValid"
      :is-loading="isLoading"
      @click="onSubmit"
    >
      JOIN
    </x-button>
    <InfoModal
      ref="errorModal"
      title="UH OH! SOMETHING WENT WRONG."
      hide-close-button
      cta="TRY AGAIN"
      @cta="$refs.errorModal.hide()"
    />
    <modal
      ref="successModal"
      style="height: 100vh; width: 100vw; text-align: center"
      x-style="display: grid; height: 100vh; width: 100vw; align-content: center; justify-items: center; position: relative; gap: 1.5rem;"
      x-class=" container-expand"
      @hide="$router.push('/login')"
    >
      <AppNavButton
        icon="close"
        style="
          position: absolute;
          top: 0;
          right: 0;
          margin-top: 1rem;
          margin-right: 1rem;
        "
        @click="$refs.successModal.hide()"
      />
      <h3
        style="
          font-family: GTSuper;
          font-size: 32px;
          padding: 0 2rem;
          line-height: 1.2;
        "
      >
        Confirmation email sent!
      </h3>
      <p style="width: 320px">
        Please check your inbox or spam folder and follow the instructions to
        verify your account.
      </p>
      <a href="mailto:" target="_blank" style="margin-top: 1rem">
        <XButton type="large">Go to email</XButton>
      </a>
      <div style="margin-top: 2rem; position: relative">
        <p>Didn't recieve the email?</p>

        <p
          class="signup-email-resend-button"
          :data-loading="resendingEmailInProgress"
          @click="onResendEmail"
        >
          Resend Email
        </p>
        <Transition name="fade">
          <p v-if="resendingEmailMessage" class="signup-email-resend-success">
            {{ resendingEmailMessage }}
          </p>
        </Transition>
      </div>
    </modal>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import { AUTH_PROVIDERS } from '../../constants/AUTH_PROVIDERS'
import { REGEX } from '../../constants/REGEX'
import { utils } from '../../other/utils/utils'
import InfoModal from '../../components-rf/modals/InfoModal.vue'
import AppNavButton from '../../components-rf/AppNavButton.vue'
import XButton from '../../components/common/global/XButton.vue'
import AppHeader from '../../components-rf/AppHeader.vue'
import XLabel from '../../components/common/global/XLabel.vue'
import FormInput from '../../components/utils/FormInput.vue'

export default {
  name: 'Signup',
  components: {
    InfoModal,
    AppNavButton,
    XButton,
    AppHeader,
    XLabel,
    FormInput,
  },
  mounted() {
    this.$nextTick(() => {
      this.$refs.firstNameField.$el.focus()
      this.forms.vset.register.password_confirmation(true)
    })
  },
  data() {
    return {
      AUTH_PROVIDERS,
      REGEX,
      isLoading: false,
      resendingEmailInProgress: false,
      resendingEmailMessage: '',
      registeredUserId: undefined,
      confirmPassError: '',
      forms: {
        register: {
          first_name: '',
          last_name: '',
          email: '',
          password: '',
          password_confirmation: '',
          terms: false,
          policy: false,
        },
      },
    }
  },
  watch: {
    'forms.register.first_name'(val) {
      this.forms.first_name = utils.capitalizeSentence(val)
    },
    'forms.register.last_name'(val) {
      this.forms.last_name = utils.capitalizeSentence(val)
    },
  },
  computed: {
    formValid() {
      return this.forms.vget.register.form && !this.confirmPassError
    },
  },
  methods: {
    ...mapActions('AuthModule', ['register', 'resendEmail']),
    validateConfirmPassword(value) {
      this.confirmPassError = !value
        ? this.$t('form.password-required')
        : value !== this.forms.register.password
        ? this.$t('form.password-doesnt-match')
        : ''
    },
    onSubmit() {
      if (!this.formValid) return
      this.isLoading = true
      this.register(this.forms.register)
        .then(res => {
          this.registeredUserId = res.user.id
          this.$refs.successModal.show()
        })
        .catch(() => this.$refs.errorModal.show())
        .finally(() => (this.isLoading = false))
    },
    onResendEmail() {
      if (this.resendingEmailInProgress === true) return
      this.resendingEmailInProgress = true
      let message = ''
      this.resendEmail({ id: this.registeredUserId })
        .then(() => {
          message = 'Email sent successfully'
        })
        .catch(e => {
          if (e.message) {
            const errMessage = e.message.split('|')[2]
            if (errMessage)
              message = errMessage.charAt(0).toUpperCase() + errMessage.slice(1)
          }
        })
        .finally(() => {
          this.resendingEmailInProgress = false
          this.resendingEmailMessage = message
          setTimeout(() => (this.resendingEmailMessage = ''), 3000)
        })
    },
  },
}
</script>

<style scoped lang="scss">
.signup-tos-pp:focus {
  text-decoration: underline;
}
.signup-checkbox-group {
  margin-top: 1rem;
  display: grid;
  column-gap: 0.5rem;
  row-gap: 1rem;
  align-items: center;
  grid-template-columns: 15px 1fr;
  & > p > a {
    color: var(--color-primary);
    transition: color 0.2s ease-in-out;
    cursor: pointer;
    &:hover {
      color: var(--color-secondary);
    }
  }
}

.signup-email-resend-button {
  font-family: DDin;
  font-size: 14px;
  text-decoration: underline;
  padding: 0.5rem;
  transition: all 0.3s ease;
  &:not([data-loading='true']) {
    cursor: pointer;
    color: black;
  }
  &[data-loading='true'] {
    color: grey;
  }
}
.signup-email-resend-success {
  font-family: DDin;
  font-size: 14px;
  position: absolute;
  width: 100%;
  color: var(--color-primary);
}
</style>
